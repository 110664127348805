import { useToast } from 'vue-toastification';
import AppToast from '@/components/AppToast.vue';

export const useToaster = () => {
  const toast = useToast();

  const content = (title, type) => {
    return {
      component: AppToast,
      props: {
        title,
        type,
      },
    };
  };

  const success = (title) => {
    toast.success(content(title, 'success'));
  };
  const info = (title) => {
    toast.info(content(title, 'info'));
  };
  const error = (title) => {
    toast.error(content(title, 'error'));
  };
  const warning = (title) => {
    toast.warning(content(title, 'warning'));
  };

  return {
    success,
    info,
    error,
    warning,
  };
};
