<template>
  <AppLoader />
  <AppHeader />
  <router-view :key="$route.fullPath" />
  <NewAppFooter />
  <ModalAuth />
  <LimitsModal />
  <SocialModal />
  <FeedbackModal />
  <SvgIconsSprite />
</template>

<script>
import dayjs from '@/plugins/dayjs';
import getScrollbarWidth from '@/utils/getScrollbarWidth';
import AppLoader from '@/components/AppLoader.vue';
import AppHeader from '@/components/AppHeader/AppHeader.vue';
import NewAppFooter from '@/components/NewAppFooter.vue';
import ModalAuth from '@/components/ModalAuth/ModalAuth.vue';
import SvgIconsSprite from '@/components/SvgIconsSprite';
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';
import { useColorsStore } from '@/stores/useColorsStore';
import { useCategoriesStore } from '@/stores/useCategoriesStore';
import 'vue-toastification/dist/index.css';
import '@/assets/styles/animations.css';
import '@/assets/styles/modal.css';
import '@/assets/styles/vue-select.css';
import '@/assets/styles/vue-color-kit.css';
import FeedbackModal from './components/modals/FeedbackModal/FeedbackModal.vue';
import LimitsModal from './components/modals/LimitsModal/LimitsModal.vue';
import SocialModal from './components/modals/SocialModal/SocialModal.vue';

export default {
  setup() {
    return {
      appStore: useAppStore(),
      userStore: useUserStore(),
      colorsStore: useColorsStore(),
      categoriesStore: useCategoriesStore(),
    };
  },
  data: () => ({
    scrollbarWidth: getScrollbarWidth(),
    dayjs,
  }),
  components: {
    AppLoader,
    NewAppFooter,
    AppHeader,
    ModalAuth,
    SvgIconsSprite,
    FeedbackModal,
    LimitsModal,
    SocialModal,
  },
  computed: {
    isAnyModalVisible() {
      return this.appStore.isAnyModalVisible;
    },
    isAuth() {
      return this.userStore.isAuthenticated;
    },
    countdown() {
      return this.appStore.countdown;
    },
  },
  methods: {
    init() {
      const ro = new ResizeObserver(() => {
        this.scrollbarWidth = getScrollbarWidth();
      });
      ro.observe(document.body);

      this.colorsStore.getColors();

      if (this.isAuth) {
        this.userStore.getUser();
      }

      this.setCountdown();
    },
    setCountdown() {
      const today = this.dayjs().tz();
      const nextMonday = this.dayjs()
        .tz()
        .add(today.day() > 0 ? 1 : 0, 'week')
        .day(1)
        .hour(0)
        .minute(0)
        .second(0);
      let diff = nextMonday.diff(today, 'seconds');

      this.appStore.setCountdownTime(diff);

      setInterval(() => {
        this.appStore.setCountdownTime(this.countdown.time - 1);
      }, 1000);
    },
  },
  watch: {
    isAnyModalVisible: {
      immediate: true,
      handler() {
        const hasPageScrollbar =
          document.body.scrollHeight > window.innerHeight;
        if (this.isAnyModalVisible && hasPageScrollbar) {
          window.document.body.classList.add('hide-scrollbar');
        } else {
          setTimeout(() => {
            window.document.body.classList.remove('hide-scrollbar');
          }, 300);
        }
      },
    },
    scrollbarWidth: {
      immediate: true,
      handler() {
        document.documentElement.style.setProperty(
          '--scrollbar-width',
          `${this.scrollbarWidth}px`,
        );
      },
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
body {
  --color-accent: #2467ec;
  --color-success: #4caf50;
  --color-info: #2467ec;
  --color-error: #ec675c;
  --color-warning: #ffc107;
  --color-dark: #25262c;
  --color-white: #fff;
  --color-grey: #e1e1e1;
  --font: 'Gilroy', Arial, Helvetica, sans-serif;

  background-color: #f2f2f2;
  overflow-x: hidden;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-dark);

  &.hide-scrollbar {
    overflow: hidden;
    padding-right: var(--scrollbar-width);
  }
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 113.6%;

  @media only screen and (max-width: 833px) {
    font-size: 26px;
  }
}
h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 125%;
  margin-bottom: 0.65em;
}
h4,
.text_h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25em;
}
.text_h4 {
  color: #989898;
}
.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}
.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #989898;
}
.input {
  --border-color: #cfcfcf;
  background: none;
  box-shadow: 0 0 0 2px var(--border-color) inset;
  border: none;
  box-sizing: border-box;
  border-radius: 15px;
  height: 45px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  color: inherit;
  padding: 0 1em;
  width: 100%;
  display: block;
  outline: none;
  transition: box-shadow 0.125s ease;

  &:focus {
    --border-color: var(--color-accent);
  }
}

.main_column {
  padding: 0 20px;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.Vue-Toastification__toast {
  margin-bottom: 10px;
  border-radius: 15px;
  min-height: unset;
  font-family: inherit;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  background: none;
  position: relative;
  color: var(--color-dark);
  background: #fff;
  will-change: transform;
}
.Vue-Toastification__toast::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--toast-color);
  opacity: 0.25;
  border-radius: 15px;
  left: 0;
  top: 0;
}
.Vue-Toastification__toast--warning {
  --toast-color: var(--color-warning);
}
.Vue-Toastification__toast--success {
  --toast-color: var(--color-success);
}
.Vue-Toastification__toast--error {
  --toast-color: var(--color-error);
}
.Vue-Toastification__toast--info {
  --toast-color: var(--color-info);
}
.Vue-Toastification__close-button {
  font-weight: normal;
}
.Vue-Toastification__icon {
  margin-right: 15px;
}
.Vue-Toastification__container.bottom-left,
.Vue-Toastification__container.bottom-right,
.Vue-Toastification__container.bottom-center {
  bottom: 0;
}
</style>
