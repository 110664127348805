<template>
  <div class="alert">
    <div class="alert_icon">
      <img src="../assets/images/emoji-sad-cat.png" alt="" />
    </div>
    <div class="alert_content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.alert {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  text-align: left;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--color-error);
    opacity: 0.25;
    border-radius: 15px;
    left: 0;
    top: 0;
  }
}
.alert_icon {
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  background: #f4bdb8;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  img {
    width: 35px;
    height: 35px;
  }
}
.alert_content {
  position: relative;
  align-self: center;
}
</style>
