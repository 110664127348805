<template>
  <AppModal v-model="isVisible" :close="closeModal">
    <div class="modal">
      <button class="modal_close" @click="closeModal"><span></span></button>
      <h3>{{ $t('authModal.littleAboutYou') }}</h3>
      <div class="modal--content">
        <AppSelect
          :list="socialsList"
          v-model="socialNetwork"
          :placeholder="$t('socialModal.socialNetwork')"
          :clearable="false"
          :invalid="v$.socialNetwork.$invalid && v$.socialNetwork.$dirty"
          :error="v$.socialNetwork.$errors[0]?.$message"
        />
        <AppInput
          v-model="socialLink"
          :placeholder="$t('socialModal.urlToSocial')"
          type="text"
          :invalid="v$.socialLink.$invalid && v$.socialLink.$dirty"
          :error="v$.socialLink.$errors[0]?.$message"
        />
        <AppButton
          color="primary"
          type="submit"
          spinner
          :loading="isLoading"
          @click="onSubmit"
        >
          {{ $t('modalUser.save') }}
        </AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppInput from '@/components/inputs/AppInput/AppInput.vue';
import { useVuelidate } from '@vuelidate/core';
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';
import { required, helpers } from '@vuelidate/validators';
import AppSelect from '@/components/AppSelect.vue';

const { withMessage } = helpers;

export default {
  setup() {
    return {
      v$: useVuelidate(),
      appStore: useAppStore(),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      isLoading: false,
      socialLink: '',
      socialNetwork: '',
      socialsList: [
        { label: 'Telegram', value: 'Telegram' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Twitter', value: 'Twitter' },
        { label: 'WhatsApp', value: 'WhatsApp' },
        { label: this.$t('socialModal.other'), value: 'Other' },
      ],
    };
  },
  validations() {
    return {
      socialLink: {
        required: withMessage(
          () => this.$t('socialModal.socialCantBeEmpty'),
          required,
        ),
      },
      socialNetwork: {
        required: withMessage(
          () => this.$t('socialModal.socialNetworkCantBeEmpty'),
          required,
        ),
      },
    };
  },
  computed: {
    isVisible() {
      return this.appStore.isModalSocialVisible;
    },
  },
  methods: {
    closeModal() {
      this.v$.$reset();
      this.appStore.showModalSocialVisible(false);
    },
    async onSubmit() {
      this.v$.$touch();

      if (this.v$.$invalid || this.userIsValidating) {
        return false;
      }

      this.isLoading = true;

      await this.userStore.updateUserInfo({
        ...this.userStore.user,
        social_link: this.socialLink,
        social_network: this.socialNetwork.value,
      });

      this.closeModal();

      this.isLoading = false;
    },
  },
  components: { AppInput, AppSelect },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 50px;

  &--content {
    display: grid;
    row-gap: 10px;
  }
}
</style>
