<template>
  <svg
    viewBox="0 0 38 38"
    class="icon"
    :style="{
      '--size': typeof size === 'number' ? `${size}px` : size,
    }"
    :color="'rgb(37, 38, 44)'"
    stroke="currentColor"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="2">
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: '1em',
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: var(--size);
  height: var(--size);
}
</style>
