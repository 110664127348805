import { useUserStore } from '@/stores/useUserStore';
import axios from 'axios';

const axiosInstance = axios.create();

const token = localStorage.getItem('authToken');
if (token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const userStore = useUserStore();
    if (error?.response?.status === 401) {
      userStore.logout();
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
