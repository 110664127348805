<template>
  <AppModal v-model="isModalVisible" :close="closeModal">
    <div class="modal">
      <button class="modal_close" @click="closeModal"><span></span></button>
      <transition name="fade" mode="out-in">
        <!-- LOADER -->
        <div class="modal_content loader" v-if="isLoaderVisible">
          <LoaderOval :size="50" />
        </div>

        <!-- SIGN IN -->
        <div class="modal_content" v-else-if="modalType === 'signin'">
          <h3 v-if="modalAuthProps?.warningBeforeEdit">
            {{ $t('authModal.forCreatingBannerYouShouldAuth') }}
          </h3>
          <h3 v-else>{{ $t('authModal.auth') }}</h3>
          <AppButton
            class="googleBtnWrapper"
            outlined
            spinner
            :loading="isGoogleLoading"
            color="black"
            @click="onGoogleClick"
          >
            <div class="googleBtn">
              <GoogleIcon />
              <div>{{ $t('authModal.continueWithGoogle') }}</div>
            </div>
          </AppButton>
          <form @submit.prevent="signin" novalidate>
            <div class="authModalOrDivider">
              {{ $t('app.or') }}
            </div>
            <ErrorAlert v-if="authStatus.error">
              <span v-if="authStatus.error?.response?.status === 422">
                {{ $t('authModal.invalidLoginOrPas') }}
              </span>
              <span v-else>
                {{ $t('authModal.pleaseTryAgain') }}
              </span>
            </ErrorAlert>
            <div class="form_group">
              <AppInput
                v-model="email"
                :placeholder="$t('authModal.yourEmail')"
                type="email"
                :invalid="v$.email.$invalid && v$.email.$dirty"
                :error="v$.email.$errors[0]?.$message"
              />
            </div>
            <div class="form_group">
              <AppInput
                v-model="password"
                :placeholder="$t('authModal.yourPassword')"
                type="password"
                :invalid="v$.password.$invalid && v$.password.$dirty"
                :error="v$.password.$errors[0]?.$message"
              />
            </div>
            <AppButton
              color="primary"
              type="submit"
              spinner
              :loading="isSigningIn"
            >
              {{ $t('authModal.signIn') }}
            </AppButton>
          </form>
          <AppSpacer :size="35" vertical />
          <div>
            <AppButton color="primary" blank @click="showForgotPassword">
              {{ $t('authModal.forgotPassword') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <div class="newUser">
            <span class="text_h4">
              {{ $t('authModal.dontHaveAccYet') }}
            </span>
            <AppButton color="primary" blank @click="showSignUp">
              {{ $t('authModal.signUp') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <RecaptchaCompliance />
        </div>

        <!-- SIGN UP -->
        <div
          class="modal_content"
          v-else-if="modalType === 'signup' && step === 1"
        >
          <h3>{{ $t('authModal.registration') }}</h3>
          <form @submit.prevent="signupNextStep" novalidate>
            <div class="form_group">
              <AppInput
                v-model="email"
                :placeholder="$t('authModal.yourEmail')"
                type="email"
                :invalid="(v$.email.$invalid && v$.email.$dirty) || emailExist"
                :error="registrationEmailErr"
              />
            </div>
            <div class="form_group">
              <AppInput
                v-model="password"
                :placeholder="$t('authModal.yourPassword')"
                type="password"
                :invalid="v$.password.$invalid && v$.password.$dirty"
                :error="v$.password.$errors[0]?.$message"
              />
            </div>
            <AppButton color="primary" type="submit">{{
              $t('authModal.continue')
            }}</AppButton>
          </form>
          <AppSpacer :size="35" vertical />
          <div class="existingUser">
            <span class="text_h4">
              {{ $t('authModal.allreadyHaveAnAcc') }}
            </span>
            <AppButton color="primary" blank @click="showSignIn">
              {{ $t('authModal.loginToIt') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <RecaptchaCompliance />
        </div>

        <!-- SIGN UP 2ND STEP -->
        <div
          class="modal_content"
          v-else-if="modalType === 'signup' && step === 2"
        >
          <h3>{{ $t('authModal.littleAboutYou') }}</h3>
          <form @submit.prevent="signup" novalidate name="reg-form">
            <ErrorAlert v-if="signupStatus?.type === 'error'">
              {{ $t('authModal.pleaseTryAgain') }}
            </ErrorAlert>
            <div class="form_group">
              <AppInput
                v-model="name"
                :placeholder="$t('authModal.yourName')"
                type="text"
                :invalid="v$.name.$invalid && v$.name.$dirty"
                :error="v$.name.$errors[0]?.$message"
              />
            </div>
            <AppButton
              color="primary"
              type="submit"
              spinner
              :loading="signupStatus.type === 'loading'"
            >
              {{ $t('authModal.save') }}
            </AppButton>
          </form>
          <AppSpacer :size="35" vertical />
          <div class="existingUser">
            <span class="text_h4">{{ $t('authModal.allreadyHaveAnAcc') }}</span>
            <AppButton color="primary" blank @click="showSignIn">
              {{ $t('authModal.loginToIt') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <AppCheckbox v-model="gift" class="gift">
            <span class="text_h4">
              {{ $t('authModal.wantGetAd') }}
            </span>
          </AppCheckbox>
        </div>

        <!-- FORGOT PASSWORD -->
        <div
          class="modal_content"
          v-else-if="modalType === 'forgotPassword' && step === 1"
        >
          <h3>
            {{ $t('authModal.forgotPassword') }}
          </h3>
          <form @submit.prevent="forgotPasswordNextStep" novalidate>
            <ErrorAlert v-if="forgotPasswordStatus.error">
              {{ $t('authModal.failedConfirmEmail') }}
            </ErrorAlert>
            <div class="form_group">
              <AppInput
                v-model="email"
                :placeholder="$t('authModal.yourEmail')"
                type="email"
                :invalid="v$.email.$invalid && v$.email.$dirty"
                :error="v$.email.$errors[0]?.$message"
              />
            </div>
            <AppButton
              color="primary"
              type="submit"
              spinner
              :loading="forgotPasswordStatus.type === 'loading'"
            >
              {{ $t('authModal.continue') }}
            </AppButton>
          </form>
          <AppSpacer :size="35" vertical />
          <div class="existingUser">
            <span class="text_h4">
              {{ $t('authModal.allreadyHaveAnAcc') }}
            </span>
            <AppButton color="primary" blank @click="showSignIn">
              {{ $t('authModal.loginToIt') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <RecaptchaCompliance />
        </div>

        <!-- FORGOT PASSWORD 2ND STEP -->
        <div
          class="modal_content forgotPassword"
          v-else-if="modalType === 'forgotPassword' && step === 2"
        >
          <h3>
            {{ $t('authModal.recovering') }}<br />{{
              $t('authModal.recoveringYourPassword')
            }}
          </h3>
          <p class="text">
            {{ $t('authModal.emailWithInsctructionsWasSended') }}
          </p>
          <AppSpacer :size="25" vertical />
          <div class="form_group email">
            <input
              type="email"
              class="input"
              :placeholder="$t('authModal.yourEmail')"
              :value="email"
              disabled
            />
          </div>
          <AppSpacer :size="35" vertical />
          <div class="wrongEmail">
            <span class="text_h4">
              {{ $t('authModal.providedInvalidEmail') }}
            </span>
            <AppButton color="primary" blank @click="showSignUp">
              {{ $t('authModal.makeAReRegistration') }}
            </AppButton>
          </div>
        </div>

        <!-- RESET PASSWORD -->
        <div
          class="modal_content"
          v-else-if="modalType === 'resetPassword' && step === 1"
        >
          <h3>
            {{ $t('authModal.makeNewPas') }}
          </h3>
          <form @submit.prevent="resetPassword" novalidate>
            <ErrorAlert v-if="resetPasswordStatus.error">
              {{ $t('authModal.unableToUpdatePassword') }}
            </ErrorAlert>
            <div class="form_group">
              <AppInput
                v-model="password"
                :placeholder="$t('authModal.newPassword')"
                type="password"
                :invalid="v$.password.$invalid && v$.password.$dirty"
                :error="v$.password.$errors[0]?.$message"
              />
            </div>
            <AppButton
              color="primary"
              type="submit"
              spinner
              :loading="resetPasswordStatus.type === 'loading'"
            >
              {{ $t('authModal.update') }}
            </AppButton>
          </form>
        </div>

        <!-- RESET PASSWORD SUCCESS -->
        <div
          class="modal_content"
          v-else-if="modalType === 'resetPassword' && step === 2"
        >
          <h3>{{ $t('authModal.yourPasswordUpdatedSuccessfully') }}</h3>
          <p class="text">
            {{ $t('authModal.yourDroppedAndUpdatedPassword') }}
          </p>
          <AppSpacer :size="35" vertical />
          <AppButton color="primary" @click="showSignIn">
            {{ $t('authModal.signIn') }}
          </AppButton>
        </div>

        <!-- EMAIL CONFIRMATION -->
        <div
          class="modal_content emailConfirmation"
          v-else-if="modalType === 'emailConfirmation' && step === 1"
        >
          <h3>
            {{ $t('authModal.verification') }}<br />
            {{ $t('authModal.verificationOfYourEmail') }}
          </h3>
          <ErrorAlert v-if="sendEmailConfirmationStatus.error">
            {{ $t('authModal.failedConfirmEmail') }}
          </ErrorAlert>
          <p class="text">
            {{ $t('authModal.emailWasSendedClickOnUrlInIt') }}
          </p>
          <AppSpacer :size="25" vertical />
          <div class="form_group email">
            <input
              type="email"
              class="input"
              :placeholder="$t('authModal.yourEmail')"
              :value="email"
              disabled
            />
          </div>
          <AppSpacer :size="35" vertical />
          <div class="noEmailRecieved">
            <p class="text_h4">
              {{ $t('authModal.didntReceiveALetter') }}
            </p>
            <p class="text_h4">
              {{ $t('authModal.checkSpamFolder') }}
            </p>
            <AppButton
              blank
              spinner
              color="primary"
              @click="resendEmailConfirmation"
              :loading="sendEmailConfirmationStatus.type === 'loading'"
            >
              {{ $t('authModal.sendAgain') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <div class="wrongEmail">
            <span class="text_h4">
              {{ $t('authModal.providedInvalidEmail') }}
            </span>
            <AppButton color="primary" blank @click="showSignUp">
              {{ $t('authModal.makeAReRegistration') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <p class="text">
            {{ $t('authModal.tryToReregister') }}
          </p>
        </div>

        <!-- EMAIL CONFIRMATION SECOND ATTEMPT -->
        <div
          class="modal_content emailConfirmation"
          v-else-if="modalType === 'emailConfirmation' && step === 2"
        >
          <h3>
            {{ $t('authModal.weResend') }}<br />
            {{ $t('authModal.theLetter') }}
          </h3>
          <div class="form_group email">
            <input
              type="email"
              class="input"
              :placeholder="$t('authModal.yourEmail')"
              :value="email"
              disabled
            />
          </div>
          <AppSpacer :size="35" vertical />
          <div class="noEmailRecieved">
            <p class="text_h4">
              {{ $t('authModal.stillHavntReceivedLetter') }}
            </p>
            <p class="text_h4">
              {{ $t('authModal.checkSpamFolder') }}
            </p>
            <AppSpacer :size="10" vertical />
            <AppButton outlined color="black">
              <AppIcon icon="telegram" :size="26" />
              {{ $t('authModal.contactUsOnTelegram') }}
            </AppButton>
            <AppSpacer :size="10" vertical />
            <AppButton outlined color="black">
              <AppIcon icon="envelope" :size="24" />
              {{ $t('authModal.contactUsViaEmail') }}
            </AppButton>
            <AppSpacer :size="15" vertical />
            <p class="text_h4">
              {{ $t('authModal.averageResponseTime') }}
              <strong>
                {{ $t('authModal.sevenMinutes') }}
              </strong>
            </p>
          </div>
          <AppSpacer :size="35" vertical />
          <div class="wrongEmail">
            <span class="text_h4">{{
              $t('authModal.providedInvalidEmail')
            }}</span>
            <AppButton color="primary" blank @click="showSignUp">
              {{ $t('authModal.makeAReRegistration') }}
            </AppButton>
          </div>
          <AppSpacer :size="35" vertical />
          <p class="text">
            {{ $t('authModal.tryToReregister') }}
          </p>
        </div>

        <!-- EMAIL VERIFY -->
        <div
          class="modal_content emailVerify"
          v-else-if="modalType === 'emailVerify'"
        >
          <div v-if="confirmEmailStatus.error">
            <h3>
              {{ $t('authModal.emailConfirmation') }}
            </h3>
            <ErrorAlert>
              {{ $t('authModal.unableToConfirmEmail') }}
            </ErrorAlert>
          </div>
          <div class="emailVerify_success" v-else>
            <h3>
              {{ $t('authModal.emailConfirmedSuccessfully') }}
            </h3>
            <p class="text">
              {{ $t('authModal.youDoneThat') }}
            </p>
            <AppSpacer :size="10" vertical />
            <AppButton outlined color="black">
              <AppIcon icon="telegram" :size="26" />
              {{ $t('authModal.joinPrivateChannel') }}
            </AppButton>
            <AppSpacer :size="35" vertical />
            <AppButton color="primary" @click="closeModal">
              {{ $t('authModal.startUsing') }}
            </AppButton>
          </div>
        </div>

        <!-- SUSPICIOUS ACTIVITY -->
        <div
          class="modal_content suspiciousActivity"
          v-else-if="modalType === 'suspiciousActivity'"
        >
          <h3>
            {{ $t('authModal.weNoticedSuspiciousActivity') }}
          </h3>
          <ErrorAlert>
            {{ $t('authModal.sorryEntryIsNotAllowed') }}
            <br />
            {{ $t('authModal.weNoticedMoreThan1Man') }}
          </ErrorAlert>
          <div class="suspiciousActivity_contacts">
            <p class="text_h4">
              {{ $t('authModal.ifItsAnErrorContactUs') }}
            </p>
            <AppSpacer :size="10" vertical />
            <AppButton outlined color="black">
              <AppIcon icon="telegram" :size="26" />
              {{ $t('authModal.unblockAcc') }}
            </AppButton>
            <AppSpacer :size="10" vertical />
            <AppButton outlined color="black">
              <AppIcon icon="envelope" :size="24" />
              {{ $t('authModal.unblockByEmail') }}
            </AppButton>
          </div>
        </div>

        <!-- SUCCES REGISTRATION -->
        <div class="modal_content" v-else-if="modalType === 'successRegister'">
          <h3>{{ $t('authModal.successfullyRegistered') }}</h3>

          <div class="registrationSuccess--wrapper">
            <div class="registrationSuccess--description">
              {{ $t('authModal.youAreHandsome') }}
            </div>

            <AppButton
              color="black"
              class="registrationSuccess--tg"
              outlined
              @click="onJoinTG"
            >
              <SVGTGIcon class="registrationSuccess--tgIcon" />
              {{ $t('authModal.joinTgChanell') }}
            </AppButton>

            <AppButton
              color="primary"
              class="registrationSuccess--startUsing"
              @click="onStartUsing"
            >
              {{ $t('authModal.startUsing') }}
            </AppButton>
          </div>
        </div>
      </transition>
    </div>
  </AppModal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, helpers } from '@vuelidate/validators';
import AppInput from '@/components/inputs/AppInput/AppInput.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import ErrorAlert from '@/components/ErrorAlert.vue';
import RecaptchaCompliance from '@/components/RecaptchaCompliance.vue';
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';
import SVGTGIcon from '@/components/icons/SVGTGIcon.vue';
import api from '@/api';
import GoogleIcon from './icons/GoogleIcon.vue';
import LoaderOval from '../icons/LoaderOval.vue';

const { withMessage } = helpers;

export default {
  setup() {
    return {
      v$: useVuelidate(),
      appStore: useAppStore(),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      modalType: null,
      isGoogleLoading: false,
      step: 1,
      name: '',
      email: '',
      password: '',
      gift: true,
      emailExist: true,
      isSigningIn: false,
    };
  },
  validations() {
    return {
      name: {
        required: withMessage(
          () => this.$t('modalUser.nameCantBeEmpty'),
          required,
        ),
      },
      password: {
        required: withMessage(
          () => this.$t('modalUser.pasCantBeEmpty'),
          required,
        ),
        minLength: withMessage(({ $params }) => {
          return this.$t('modalUser.pasShouldBeMoreThan', [$params.min]);
        }, minLength(4)),
      },
      email: {
        required: withMessage(
          () => this.$t('modalUser.emailCantBeEmpty'),
          required,
        ),
        email: withMessage(() => this.$t('modalUser.invalidEmail'), email),
      },
    };
  },
  methods: {
    async onGoogleClick(e) {
      e.preventDefault();

      this.isGoogleLoading = true;

      try {
        const { data } = await api.auth.googleAuth();

        if (!data.success) {
          throw new Error();
        }

        window.location.replace(data.data.url);
      } catch (e) {
        console.log(e);
      } finally {
        this.isGoogleLoading = false;
      }
    },

    closeModal() {
      this.appStore.showModalAuth(false);
    },

    showSignIn() {
      this.appStore.showModalAuth('signin');
    },
    async signin() {
      this.v$.$touch();
      if (this.v$.email.$invalid || this.v$.password.$invalid) {
        return false;
      }

      try {
        this.isSigningIn = true;
        /* const data = */ await this.userStore.login({
          email: this.email,
          password: this.password,
        });

        // if (data?.confirmed_at === null) {
        //   this.appStore.showModalAuth('emailConfirmation');
        // } else if (data?.confirmed_at) {
        await this.userStore.getUser();
        // this.$router.push({ name: this.$route.params.nextRoute });

        if (this.appStore.modalAuthProps?.onAuth) {
          this.appStore.modalAuthProps?.onAuth();
        } else {
          this.$router.push({ name: 'home' });
        }

        this.closeModal();
        // }
      } catch (e) {
        console.error(this.$t('authModal.unableToSignIn'));
      } finally {
        this.isSigningIn = false;
      }
    },

    showSignUp() {
      this.appStore.showModalAuth('signup');
    },
    async signupNextStep() {
      this.v$.$touch();
      if (this.v$.email.$invalid || this.v$.password.$invalid) {
        return false;
      }

      try {
        const response = await api.users.checkEmail(this.email);

        if (response.status === 200) {
          this.v$.$reset?.();

          this.step++;
        }
      } catch (e) {
        this.emailExist = true;
      }
    },
    async signup() {
      this.v$.$touch();
      if (
        this.v$.email.$invalid ||
        this.v$.password.$invalid ||
        this.v$.name.$invalid
      ) {
        return false;
      }

      try {
        await this.userStore.signup({
          name: this.name,
          email: this.email,
          password: this.password,
          gift: this.gift,
        });

        this.appStore.showModalAuth('successRegister');
        // this.appStore.showModalAuth('emailConfirmation');
      } catch (e) {
        console.error(this.$t('authModal.unableToSignUp'));
      }
    },

    showForgotPassword() {
      this.appStore.showModalAuth('forgotPassword');
    },
    async forgotPasswordNextStep() {
      this.v$.$touch();
      if (this.v$.email.$invalid) {
        return false;
      }

      try {
        await this.userStore.forgotPassword({
          email: this.email,
        });
        this.step++;
      } catch (error) {
        console.error(this.$t('authModal.unableToSendMailWithConfirm'));
      }
    },

    // showEmailConfirmation() {
    //   this.appStore.showModalAuth('emailConfirmation');
    // },
    async resendEmailConfirmation() {
      try {
        await this.userStore.sendEmailConfirmation({
          email: this.email,
        });
        this.step++;
      } catch (error) {
        console.error(this.$t('authModal.unableToSendMailWithConfirm'));
      }
    },
    async confirmEmail() {
      try {
        this.appStore.showModalAuth('emailVerify');
        await this.userStore.confirmEmail({
          verify: this.$route.query.verify,
        });
      } catch (error) {
        console.error(this.$t('authModal.unableToConfirmEmailErr'));
      }
    },

    async showResetPassword() {
      try {
        const { data } = await api.auth.checkResetPasswordToken({
          token: this.$route.query.reset,
        });

        if (data.success) {
          this.appStore.showModalAuth('resetPassword');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async resetPassword() {
      this.v$.$touch();
      if (this.v$.password.$invalid) {
        return false;
      }

      try {
        await this.userStore.resetPassword({
          password: this.password,
          token: this.$route.query.reset,
        });
        this.step++;
      } catch (error) {
        console.error(this.$t('authModal.unableToUpdatePasswordErr'));
      }
    },

    onJoinTG() {
      window.open('https://t.me/+x2aJilO6ZHQ3ZmQy', '_blank');
    },
    onStartUsing() {
      this.appStore.showModalAuth(false);
    },
  },
  computed: {
    modalAuthType() {
      return this.appStore.isModalAuthVisible;
    },
    modalAuthProps() {
      return this.appStore.modalAuthProps;
    },
    authStatus() {
      return this.userStore.authStatus;
    },
    signupStatus() {
      return this.userStore.signupStatus;
    },
    sendEmailConfirmationStatus() {
      return this.userStore.sendEmailConfirmationStatus;
    },
    confirmEmailStatus() {
      return this.userStore.confirmEmailStatus;
    },
    forgotPasswordStatus() {
      return this.userStore.forgotPasswordStatus;
    },
    resetPasswordStatus() {
      return this.userStore.resetPasswordStatus;
    },
    isModalVisible() {
      return !!this.modalAuthType;
    },
    isLoaderVisible() {
      return this.confirmEmailStatus.type === 'loading';
    },
    registrationEmailErr() {
      if (this.emailExist) {
        return this.$t('authModal.emailAlreadyExist');
      }

      return this.v$.email.$errors[0]?.$message;
    },
  },
  watch: {
    email: {
      immediate: true,
      handler() {
        this.emailExist = false;
      },
    },
    modalAuthType: {
      immediate: true,
      handler(newVal, oldVal) {
        this.step = 1;

        if (this.modalAuthType) {
          this.modalType = this.modalAuthType;
        }

        if (oldVal === 'emailVerify') {
          const query = { ...this.$route.query };
          delete query.verify;
          this.$router.replace({ query });
        }
        if (oldVal === 'resetPassword') {
          const query = { ...this.$route.query };
          delete query.reset;
          this.$router.replace({ query });
        }

        if (this.modalAuthType === 'emailConfirmation') {
          return;
        }

        setTimeout(() => {
          this.name = '';
          this.email = '';
          this.password = '';
          this.v$.$reset?.();
        }, 300);
      },
    },
    $route: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$route.query.verify) {
          this.confirmEmail();
        }
        if (this.$route.query.reset) {
          this.showResetPassword();
        }
        if (this.$route.params.needsAuth) {
          this.showSignIn();
        }
      },
    },
  },
  components: {
    AppInput,
    AppCheckbox,
    GoogleIcon,
    RecaptchaCompliance,
    ErrorAlert,
    SVGTGIcon,
    LoaderOval,
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  padding: 50px 0;
}

form {
  width: 100%;

  .form_group {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .button {
    width: 100%;
    margin-top: 25px;
  }
}

.emailConfirmation {
  text-align: center;
}

.email {
  width: 100%;

  input {
    background: #f2f2f2;
    box-shadow: none;
    text-align: center;
  }
}

.existingUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.newUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.noEmailRecieved {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button:not(.btn-blank) {
    width: 100%;
  }
}

.wrongEmail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_h4 {
  strong {
    color: var(--color-dark);
  }
}

.suspiciousActivity_contacts {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.gift {
  .text_h4 {
    opacity: 0.5;
  }
}

.emailVerify_success {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.registrationSuccess {
  &--description {
    margin-bottom: 24px;
    color: #25262c;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  &--startUsing,
  &--tg {
    width: 100%;
  }

  &--tg {
    margin-bottom: 60px;
  }

  &--tgIcon {
    margin-right: 6px;
  }
}

.authModalOrDivider {
  padding: 20px 0 25px 0;
  color: #25262c;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  opacity: 0.55;
}

.googleBtnWrapper {
  width: 100%;
}

.googleBtn {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;
}
</style>
