<template>
  <div
    :class="{
      checkbox_checked: isChecked,
      checkbox_square: isSquare,
      checkbox_round: isRound,
      checkbox_withMargin: withMargin,
    }"
    class="checkbox_box"
  >
    <span></span>
  </div>
</template>

<script>
export default {
  props: {
    isChecked: {
      default: false,
      type: Boolean,
    },
    view: {
      default: 'square',
      type: String,
    },
    withMargin: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    isSquare() {
      return this.view === 'square';
    },
    isRound() {
      return this.view === 'round';
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  &_square {
    border-radius: 5px;
  }
  &_round {
    border-radius: 50%;
  }
  &_withMargin {
    margin-right: 10px;
  }
  &_box {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 2px #cfcfcf inset;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    position: relative;
    will-change: box-shadow;

    span {
      width: 15px;
      height: 15px;
      position: absolute;
      transform: translate(-1px, -6px) rotate(-45deg);
      will-change: transform;
      color: transparent;

      &::before,
      &::after {
        content: '';
        background: currentColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &::before {
        height: 6px;
        width: 2px;
        transform-origin: 0 0;
        transform: scaleY(0);
      }
      &::after {
        height: 2px;
        width: 12px;
        transform-origin: left center;
        transform: scaleX(0);
      }
    }
  }

  &_checked {
    box-shadow: 0 0 0 10px var(--color-accent) inset;
    animation: shrink-bounce 150ms cubic-bezier(0.4, 0, 0.23, 1);

    span {
      color: #fff;

      &::before {
        transform: scaleY(1);
        transition: 35ms 150ms ease-in;
      }
      &::after {
        transform: scaleX(1);
        transition: 85ms 185ms ease-out;
      }
    }
  }
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
</style>
