<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M13 2.16406C10.1268 2.16406 7.37128 3.30543 5.33964 5.33707C3.30799 7.36872 2.16663 10.1242 2.16663 12.9974C2.16663 15.8706 3.30799 18.6261 5.33964 20.6577C7.37128 22.6894 10.1268 23.8307 13 23.8307C15.8731 23.8307 18.6286 22.6894 20.6603 20.6577C22.6919 18.6261 23.8333 15.8706 23.8333 12.9974C23.8333 10.1242 22.6919 7.36872 20.6603 5.33707C18.6286 3.30543 15.8731 2.16406 13 2.16406Z"
      fill="#29B6F6"
    />
    <path
      d="M18.3895 8.12761L16.3605 18.4875C16.3605 18.4875 16.2733 18.9609 15.6861 18.9609C15.3741 18.9609 15.2132 18.8125 15.2132 18.8125L10.8181 15.1655L8.66771 14.0816L5.90792 13.3476C5.90792 13.3476 5.41663 13.2057 5.41663 12.7995C5.41663 12.4609 5.922 12.2995 5.922 12.2995L17.4682 7.71269C17.4676 7.71215 17.8208 7.5854 18.0781 7.58594C18.2363 7.58594 18.4166 7.65365 18.4166 7.85677C18.4166 7.99219 18.3895 8.12761 18.3895 8.12761Z"
      fill="white"
    />
    <path
      d="M12.4583 16.5258L10.6025 18.3534C10.6025 18.3534 10.5218 18.4157 10.414 18.4184C10.3767 18.4195 10.3366 18.4135 10.2954 18.3951L10.8176 15.1641L12.4583 16.5258Z"
      fill="#B0BEC5"
    />
    <path
      d="M16.1942 9.85611C16.1026 9.73694 15.9336 9.71528 15.8145 9.80573L8.66663 14.0833C8.66663 14.0833 9.80738 17.2748 9.98125 17.8273C10.1557 18.3803 10.2954 18.3933 10.2954 18.3933L10.8176 15.1623L16.1433 10.2353C16.2624 10.1448 16.2846 9.97527 16.1942 9.85611Z"
      fill="#CFD8DC"
    />
  </svg>
</template>

<script>
export default {};
</script>
