import { ai } from '@/api/ai';
import { auth } from '@/api/auth';
import { banners } from '@/api/banners';
import { categories } from '@/api/categories';
import { colors } from '@/api/colors';
import { generatedBanners } from '@/api/generatedBanners';
import { payment } from '@/api/payment';
import { users } from '@/api/users';

export default {
  ai,
  auth,
  banners,
  categories,
  colors,
  generatedBanners,
  payment,
  users,
};
