<template>
  <svg
    class="icon"
    :class="{
      'icon--right': right,
    }"
    :style="{
      '--size': typeof size === 'number' ? `${size}px` : size,
      color: color ?? 'inherit',
    }"
  >
    <use :xlink:href="`#${icon}`" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: '1em',
    },
    right: {
      type: Boolean,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: var(--size);
  height: var(--size);
}
</style>
