import { defineStore } from 'pinia';
import api from '@/api';

export const useColorsStore = defineStore('colors', {
  state: () => {
    return {
      colors: {
        data: [],
        status: '',
        error: '',
      },
      selectedColor: null,
    };
  },

  getters: {
    isColorsLoading() {
      return this.colors.status === 'loading';
    },
    colorsList() {
      return this.colors.data;
    },
  },

  actions: {
    async getColors() {
      this.colors.status = 'loading';
      try {
        const { data } = await api.colors.get();
        this.colors.data = data.colors;
        this.colors.status = 'success';
        this.colors.error = null;
        return Promise.resolve();
      } catch (e) {
        this.colors.status = 'error';
        this.colors.error = e;
        return Promise.reject(e);
      }
    },
    async createColor(payload) {
      try {
        const { data } = await api.colors.create(payload);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateColor(payload) {
      try {
        const { data } = await api.colors.update(payload);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteColor(id) {
      try {
        const { data } = await api.colors.delete(id);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async restoreColor(id) {
      try {
        const { data } = await api.colors.restore(id);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
});
