<template>
  <AppModal v-model="isModalVisible" :close="closeModal">
    <div class="modal">
      <button class="modal_close" @click="closeModal"><span></span></button>
      <h3>
        {{ $t('feedbackModal.youLikedTheService') }}
      </h3>
      <div class="social">
        <a
          class="social--socialItem"
          v-for="[iconName, url] in social"
          :key="iconName"
          :href="url"
          target="_blank"
        >
          <AppIcon :icon="iconName" :size="18" />
        </a>
      </div>
      <div class="description">
        {{ $t('feedbackModal.serviceIsCompletely')
        }}<span>{{ $t('feedbackModal.free') }}</span
        >{{ $t('feedbackModal.andWeArePleased') }}
      </div>
      <!-- <div class="support">{{ $t('feedbackModal.supportUs') }}</div>
        <a class="productHunt" href="#" target="_blank" no-referrer>
          <ProductHuntIcon />
        </a> -->
    </div>
  </AppModal>
</template>

<script>
// import ProductHuntIcon from './icons/ProductHuntIcon.vue';
import { useAppStore } from '@/stores/useAppStore';

const social = [
  ['social-tg', 'https://t.me/+x2aJilO6ZHQ3ZmQy'],
  ['social-inst', 'http://instagram.com/ai.banner'],
];

export default {
  setup() {
    return {
      appStore: useAppStore(),
      social,
    };
  },
  computed: {
    isModalVisible() {
      return this.appStore.isModalFeedbackVisible;
    },
  },
  methods: {
    closeModal() {
      this.appStore.showModalFeedback(false);
    },
  },
  components: {
    // ProductHuntIcon,
  },
};
</script>

<style lang="scss" scoped>
.social {
  margin: 50px auto 46px auto;
  width: max-content;

  display: grid;
  grid-template-columns: repeat(2, 54px);
  column-gap: 9px;

  &--socialItem {
    position: relative;
    aspect-ratio: 1;
    background: #2467ec1a;
    border-radius: 13px;
    color: #2467ec;
    transition: 0.3s background;

    &:hover {
      background: #2467ec4d;
    }

    > * {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.description {
  margin-bottom: 39px;
  font-weight: 500;
  font-size: 14px;
  line-height: 153.6%;
  color: var(--color-dark);
  opacity: 0.7;
  text-align: center;
  span {
    color: var(--color-accent);
  }
}

.support {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 153.6%;
  text-align: center;
  color: var(--color-dark);
}

.productHunt {
  margin: 0 auto;
  width: max-content;
  display: block;
}
</style>
