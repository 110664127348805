import client from '@/plugins/axios';

export const auth = {
  // LOGIN
  async login(payload) {
    return client.post('/login', payload);
  },

  // LOGOUT
  async logout() {
    return client.post('/logout');
  },

  // SIGNUP
  async signup(payload) {
    return client.post('/register', payload);
  },

  // CONFIRM EMAIL
  async confirmEmail(payload) {
    return client.post('/confirm_email', payload);
  },

  // SEND EMAIL CONFIRMATION
  async sendEmailConfirmation(payload) {
    return client.post('/send_email_confirmation', payload);
  },

  // 1
  async forgotPassword(payload) {
    return client.post('/reset_password/email', payload);
  },

  // 2
  async checkResetPasswordToken({ token }) {
    return client.get(`/reset_password/check/${token}`);
  },

  // 3
  async resetPassword({ token, password }) {
    return client.post(`/reset_password/reset/${token}`, { password });
  },

  async googleAuth() {
    return client.get('/auth/google');
  },

  async googleCodeCallback(code) {
    return client.get(`/auth/google/callback?code=${code}`);
  },
};
