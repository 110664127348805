import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/en';
import i18n from '@/plugins/i18n';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.tz.setDefault('Europe/Kiev');
dayjs.locale(i18n.locale);

export default dayjs;
