import client from '@/plugins/axios';

export const payment = {
  async freelancerPayment() {
    return client.get('/payment_new/freelancer?paymentType=strapi');
  },
  async businessPayment() {
    return client.get('/payment_new/business?paymentType=strapi');
  },
  async individualPayment() {
    return client.get('/payment_new/individual?paymentType=strapi');
  },
  async unsubscribe() {
    return client.get('/payment/unsubscribe');
  },
};
