export default {
  common: {
    generateWithAI: 'Generate with AI',
    fieldShouldBeMoreThan: `Field must be at least {0} characters`,
    fillField: 'Fill field',
  },
  home: {
    writeToUs: 'Lightning-fast stunning designs powered by AI',
    bannerCreation:
      'Creating visuals for your social media posts has never been easier',
    whiteAboutYourCompany: 'What is the area of expertise for your business?',
    chooseAnArea: 'Choose an area',
    generate: 'Generate',
    forFeedback: 'For feedback and service improvement',
  },
  app: {
    cantBeEmpty: "Can't be empty",
    or: 'or',
    saveChanges: 'Save changes',
    login: 'Login',
    signup: 'Signup',
    logout: 'Logout',
    create: 'Create',
    getPremium: 'Get premium',
    howItWorks: 'How it works',
    aboutUs: 'About Us',
    bannerInOneMinute: 'Create banner in one minute',
    nextBannersIn: 'New banners release in:',
    gotToTilda: 'Go to Tilda',
    allUsers: 'All users',
    subscription: 'Subscription',
    allBanners: 'All banners',
    aiModel: 'Ai Model',
    settings: 'Settings',
    days: 'days | day | days',
    hours: 'hours | hour | hours',
    hoursShort: 'h',
    minutes: 'minutes | minute | minutes',
    seconds: 'seconds | second | seconds',
    banners: 'banners | banner | banners',
    en: 'Eng',
    ru: 'Ru',
    changeTariff: 'Change tariff',
    ok: 'Ok',
  },
  colors: {
    EE5151: 'Red',
    '189F25': 'Green',
    FFC133: 'Yellow',
    '2467EC': 'Blue',
    brown: 'Brown',
    FF792E: 'Orange',
    '8247FF': 'Purple',
    '25262C': 'Black',
    white: 'White',
    F2F2F2: 'Grey',
  },
  main: {
    aiGenerateBanner: 'Ai generate banner',
    yourOffer: 'Your offer',
    helpYourBusiness: 'Help your business',
    description: 'Description',
    startYourAdsFromUs: 'Start your ads from us',
    categoryCompany: 'Category company',
    cryptoAssets: 'Crypto Assets',
    brandColor: 'Brand color',
    loading: 'Loading...',
    imgProductWebsiteApp: 'Image',
    loadImage: 'Upload',
    changeImage: 'Change',
    logotypeCompany: 'Logotype',
    loadLogo: 'Upload',
    changeLogo: 'Change',
    ai: 'AI',
    changeBanner: 'Change banner',
  },
  newBanners: {
    newBannersAfter: '100 new designs will be released in:',
    weReplenishBannersEveryWeek:
      'We add new templates. Every week. For all categories.',
  },
  suggestionBanner: {
    doYouHaveWishes: 'Do you have suggestions on how to improve our service?',
    writeToTelegram: 'Contact us',
    forEvery:
      'If your comment is helpful, you will receive a whole month of unlimited premium access to AI-banners',
    doYouWant: 'Do you want to customize AI only for your company?',
    orderItIndividually: 'Order it individually',
  },
  bannerItem: {
    edit: 'Edit',
    delete: 'Delete',
    used: 'Used',
    views: 'Views',
    bannerHasBeenRemoved: 'Banner has been removed',
    restore: 'Restore',
    times: 'times | time | times',
    unableToDeleteBanner: 'Unable to delete banner :(',
    unableToRestoreBanner: 'Unable to restore banner :(',
  },
  bannerCopyright: {
    serviceOfReadyBanners: 'Service of ready banners',
  },
  footer: {
    aboutService: 'About service',
    main: 'Main',
    aboutUs: 'About us',
    cases: 'Cases',
    howItWorks: 'How it works',
    price: 'Price',
    resourses: 'Resourses',
    mediaKit: 'Media kit',
    mailing: 'Mailings',
    careService: 'Care service',
    aboutDevelopers: 'About developer',
    forDesigners: 'For designers',
    sayHello: 'Say “Hello“',
    createBanner: 'Create banner',
    madeWithLoveIn: 'Made with love in',
    termsOfUse: 'Term of Use',
    privacyPolicy: 'Privacy Policy',
    aiBannersIs:
      'Ai Banners is a modern service for creating high-converting banners',
    2022: '© 2022',
  },
  bannersList: {
    thereIsNoBanners: 'There are no banners for your request :(',
    addCategory: 'Add category',
    banners: 'Banners',
    addBanner: 'Add banner',
    goToAnalytics: 'Go to analytics',
    noBannersCreated: 'No banners created',
  },
  userEditor: {
    numberOfDownloads: 'Number of downloads',
    conversion: 'Conversion',
    downloadBanner: 'Download banner',
    edit: 'Editor',
    back: 'Back',
    changeText: 'Change text',
    changeSize: 'Change size',
  },
  usersList: {
    thereAreNoUsers: 'There are no users',
    users: 'Users',
    addUser: 'Add users',
    downloadSpreadsheet: 'Download spreadsheet',
    lookingForAUser: 'Looking for user?',
    count: 'count',
    premium: 'premium',
    name: 'Name',
    email: 'Email',
    tokens: 'Tokens',
    downloaded: 'Downloaded times',
    wastedTime: 'Time',
    notFound: 'Not found',
    allUsers: 'All users',
    premiumUsers: 'Premium users',
  },
  categoryItem: {
    categoryDeleted: 'Category deleted',
    restore: 'Restore',
    unableToDeleteCategory: 'Unable to delete category :(',
    unableToRestoreCategory: 'Unable to restore category :(',
  },
  bannerEditor: {
    colors: 'Colors',
    schemes: 'schemes',
    conversion: 'Conversion',
    forWhom: 'For whom',
    category: 'Category',
    save: 'Save',
    add: 'Add',
    useFor: 'use for',
    forEverySize: 'Use styles for every size',
    position: 'Position',
    size: 'Size',
    width: 'W',
    height: 'H',
    text: 'Text',
    font: 'Font',
    sizeAndLimit: 'Size and limit',
    limit: 'Limit',
    fontColor: 'Font color',
    svgColor: 'SVG color',
    colorInHex: 'hex color',
    image: 'Image',
    choose: 'Choose...',
    shape: 'Shape',
    rounding: 'Rounding',
    rotation: 'Rotation',
    background: 'Background',
    backgroundWithText: 'Background with text',
    copyrightVersion: 'Copyright version',
    unableToLoadBanner: 'Unable to load banner :(',
    changesSaved: 'Changes saved!',
    bannerAdded: 'Banner added!',
    unableToSave: 'Unable to save :(',
    addText: 'Add text',
    addImg: 'Add image',
    addSvg: 'Add svg',
  },
  modalCategory: {
    change: 'Change',
    add: 'Add',
    category: 'category',
    whopsError: 'Oops, something went wrong. Please try again.',
    name: 'Name',
    chooseGroup: 'Choose group',
    forUser: 'For user',
    categoryColor: 'Category color',
    changeIcon: 'Change icon...',
    chooseIcon: 'Choose icon...',
    save: 'Save',
    forAll: 'For all',
    forPremium: 'For premium',
    nameCantBeEmpty: 'Name cant be empty',
    invalidEmail: 'Invalid email',
    emailCantBeEmpty: 'Email cant be empty',
    groupCantBeEmpty: 'Group cant be empty',
    invalidColor: 'Invalid color',
    chooseIcon2: 'Choose icon',
    categoryAddedSuccessfully: 'Category added successfully',
    unableToAddCategory: 'Unable to add category',
    categoryUpdatedSuccessfully: 'Category updated successfully',
    unableToUpdateCategory: 'Unable to update category',
    willShowAsCommon:
      'Will be shown as a category to the user on the home page',
    willBeShownToSpecific: 'Will be shown to a specific user',
  },
  modalColorScheme: {
    change: 'Change',
    add: 'Add',
    colorScheme: 'color scheme',
    smthWentWrong: 'Oops, something went wrong. Please try again.',
    colorsUsed: 'Colors used:',
    mainColor: 'Main color:',
    save: 'Save',
    invalidColor: 'Invalid color',
    chooseAtLeastOneColor: 'Please select at least one color marker',
    colorSchemeUpdateSuccessfully: 'Color schemes updated successfully',
    unableToUpdateColorScheme: 'Unable to update color schemes',
  },
  modalUser: {
    change: 'Change',
    add: 'Add',
    user: 'user',
    smthWentWrong: 'Oops, something went wrong. Please try again.',
    name: 'Name',
    password: 'Password',
    tokens: 'Tokens',
    group: 'Group',
    acceptMailing: 'Send email',
    giveAdminRights: 'Give admin rights',
    save: 'Save',
    webDesign: 'Web design',
    development: 'Development',
    smmManagement: 'SMM management',
    user2: 'Users',
    premiumFreelancer: 'Premium Freelancer',
    premiumBusiness: 'Premium Business',
    individual: 'Individual',
    nameCantBeEmpty: 'Password cannot be empty',
    pasCantBeEmpty: 'Name cannot be empty',
    pasShouldBeMoreThan: `Password must be at least {0} characters`,
    emailCantBeEmpty: 'Email cannot be empty',
    invalidEmail: 'Invalid email',
    statusCantBeEmpty: 'Status cannot be empty',
    onlyLatinLettersAreAllowed: 'Only Latin letters are allowed',
    userAddedSuccessfully: 'User added successfully',
    unableToAddUser: 'Unable to add user',
    userUpdatedSuccessfully: 'User updated successfully',
    unableToUpdateUser: 'Unable to update user',
  },
  recaptchaCompliance: {
    siteIsProtectedBy: 'This site is protected by reCAPTCHA and apply',
    privacyPolicy: 'privacy policy',
    and: ' and ',
    termsOfUse: 'Terms of Use',
    google: ' Google.',
  },
  userRowItem: {
    premiumUser: 'Premiuim user',
    admin: 'Admin',
    userHasBeenDeleted: 'The user has been deleted.',
    restore: 'Restore',
    unableToDeleteUser: 'Unable to delete user :(',
    unableToRestoreUser: 'Unable to restore user :(',
  },
  bannerCard: {
    edit: 'Edit',
    availableSize: 'Available size:',
    freeDownload: 'Free download',
    editBanner: 'Edit Banner',
  },
  appInput: {
    loading: 'Loading...',
    change: 'Change',
    youCanceledImageUpload: 'You canceled image upload :(',
    unableToUploadImage: 'Unable to upload image :(',
  },
  similarBanners: {
    similarBanners: 'Similar banners:',
    noSimilarBanners: 'No similar banners',
    unableToLoadBanners: 'Unable to load banners :(',
  },
  userEditorSizes: {
    whatBannersSizeNeeds: 'What banner size do you need?',
    useFor: 'use for',
  },
  feedbackModal: {
    youLikedTheService:
      'You liked the service - please tell us about it on social networks',
    supportUs: 'Support us in product hunt',
    serviceIsCompletely: 'Our service is completely',
    free: ' free ',
    andWeArePleased:
      'and we are pleased if many people use it. We do not charge for this fee, we only ask you to tell us as much as possible about it, this gives us additional motivation to move on.',
  },
  authModal: {
    forCreatingBannerYouShouldAuth: 'You must be logged in to create a banner',
    auth: 'Sign in',
    continueWithGoogle: 'Continue with Google',
    registration: 'Sign up',
    invalidLoginOrPas: 'Oops, invalid login or password. Please, try again.',
    pleaseTryAgain: 'Oops, something went wrong. Please, try again.',
    yourEmail: 'Your email',
    yourPassword: 'Your password',
    signIn: 'Sign in',
    forgotPassword: 'Forgot password?',
    dontHaveAccYet: "Don't have an account yet?",
    signUp: 'Sign up',
    continue: 'Continue',
    loginToIt: 'Sign in',
    allreadyHaveAnAcc: 'Do you already have an account?',
    littleAboutYou: 'A little about you?',
    yourName: 'Your name',
    save: 'Save',
    emailAlreadyExist: 'This email is invalid or already taken',
    wantGetAd: 'Want to get "10 Free Advertising Sources for Business"?',
    failedConfirmEmail:
      'Oops, failed to send confirmation email. Please try again.',
    recovering: 'Recovering',
    recoveringYourPassword: 'your password',
    emailWithInsctructionsWasSended:
      'An email with instructions has been sent to your email. Click on the button in the email to reset your password and set a new one.',
    providedInvalidEmail: 'Invalid email provided?',
    makeAReRegistration: 'Make a re-registration',
    makeNewPas: 'Set a new password',
    unableToUpdatePassword:
      'Oops, failed to update password. Please try again.',
    newPassword: 'New password',
    update: 'Update',
    yourPasswordUpdatedSuccessfully:
      'Your password has been successfully updated',
    yourDroppedAndUpdatedPassword:
      'You have reset and updated your password. You can now log into your account with the new password.',
    verification: 'Verification',
    verificationOfYourEmail: 'of your email',
    emailWasSendedClickOnUrlInIt:
      'A confirmation email has been sent to your email. Click on the button in the email to use our service.',
    didntReceiveALetter: "Didn't receive a letter?",
    checkSpamFolder: "Check your spam folder and it's not there?",
    sendAgain: 'Send again',
    tryToReregister:
      'Please try registering again with a valid email address. This is important so that you can reset your password if needed.',
    weResend: 'We re-sent',
    theLetter: 'the letter',
    stillHavntReceivedLetter: "Still haven't received a letter?",
    contactUsOnTelegram: 'Contact us on Telegram.',
    contactUsViaEmail: 'Contact us via email',
    averageResponseTime: 'Average response time: ',
    sevenMinutes: '7 minutes',
    emailConfirmation: 'Email confirmation',
    unableToConfirmEmail: 'Oops, failed to verify email. Please try again.',
    emailConfirmedSuccessfully: 'Your email has been successfully verified',
    youDoneThat:
      'You did it, you are awesome, for this we give you free access to our closed telegram channel',
    joinPrivateChannel: 'Join a private channel',
    startUsing: 'Start using',
    weNoticedSuspiciousActivity: 'We noticed suspicious activity',
    sorryEntryIsNotAllowed: 'Sorry, entry is not allowed.',
    weNoticedMoreThan1Man:
      'We noticed that more than 1 person is using the account',
    ifItsAnErrorContactUs: 'If this is an error, please contact Customer Care:',
    unblockAcc: 'Unblock account',
    unblockByEmail: 'Unblock via email',
    unableToSignIn: 'Failed to login',
    unableToSignUp: 'Failed to register',
    unableToSendMailWithConfirm: 'Failed to send confirmation email',
    unableToConfirmEmailErr: 'Failed to verify email',
    unableToUpdatePasswordErr: 'Failed to update password',
    successfullyRegistered: 'Wow. You have successfully registered',
    youAreHandsome:
      'You did it, you are handsome, for this we give you free access to our private telegram channel',
    joinTgChanell: 'Join a private channel',
  },
  userEditorFields: {
    header: 'Header',
    description: 'Description',
    label: 'Category',
    list: 'List',
    buttonName: 'Button name',
    link: 'Link',
    logo: 'Logo',
    image: 'Image',
    photo: 'Photo',
    brandColor: 'Brand color',
    fillCompanyName: 'Fill company name',
  },
  listTagCrib: {
    availableTags: 'Available tags',
    im: 'Image',
  },
  giftBanner: {
    ourGiftToYou: 'Our gift to you',
    acceptGift:
      'Accept a small gift from us as a member of our community - access to a closed group of our community in Telegram',
    joinInTelegram: 'Join in Telegram',
  },
  paymentSuccess: {
    operationSuccess: 'Whee, operation successful',
    justKnow:
      "Just know that you've made an awesome investment in your business. We are glad that you are with us",
  },
  paymentError: {
    smthWentWrong: 'Oops, something went wrong',
    smthWentWrongDuringTheOperation:
      'Something went wrong during the operation, try again or you can contact our support',
    contactUsOnTelegram: 'Contact us on Telegram',
    contactUsViaEmail: 'Contact us via email',
  },
  uploadingImageModal: {
    letsAddImage:
      'You can add your own visual for integrating into the final product',
    uploadPhotoOfYourProduct: 'Please upload your visual',
    pngJpgJpeg: 'PNG, JPG, JPEG (max 2mb)',
    uploadFromLibrary: 'Upload from library',
    aiGenerate: 'AI Generate',
    skip: 'Skip',
    deleteAndUploadNew: 'Delete and upload new',
    next: 'Next',
  },
  paymentMethodModal: {
    chooseMethod: 'Select how you would like to pay',
    payViaVisaMasterCard: 'Pay via Visa / MasterCard',
    payViaTinkoff: 'Pay via Tinkoff',
  },
  availableLimit: {
    available: 'Available',
  },
  priceCard: {
    thousandsOfBanners: 'Thousands of banners. Unlimited download',
    freelanceTariff: 'Get freelance',
    businessTariff: 'Get business',
    individualTariff: 'Get individual',
    afterSubscription:
      'After registration of a premium subscription, our manager will contact you for consultation',
    month: 'month',
    serviceSubscription: 'service subscription',
    uponRequest: 'Upon request',
    forFreelancers: 'For freelancers',
    forBusiness: 'For business',
    individual: 'Individual',
    fastGeneration: 'Fast image generation for posts',
    accessToAllBanners: 'Access to all banners and priority support',
    unlimitedAccess: 'Unlimited downloads for a month',
    extraLimit: '+50 additional downloads per month',
    devSpecialForYou: 'Designing banners specifically for your brand',
    iAgreeToRegular:
      'I agree to regular debits (Only once a month - subscription price) from my bank card.',
    youCanCancelItAnytime:
      'You can cancel this at any time in your personal account',
    areYouSure: 'Are you sure?',
    youAlreadyHaveTariff:
      'You already have a tariff connected. If you connect a new one, the old one will be deleted',
  },
  limitsModal: {
    downloadsLeft: 'downloads left',
    of: 'of',
    runOutOfFreeDownloads:
      'You have run out of free downloads - to continue using - buy premium.',
    getDiscount: 'Get 70% discount',
    takeAShortSurvey:
      'You just need to take a short survey, fill out a questionnaire about our service and you will receive a special promo code',
    limitRunsOut: 'Oops, limits runs out. But we give you',
    discount70: '70% discount',
    premiumAccess: 'on premium access',
  },
  newFooter: {
    copyright: 'Copyright 2023',
    allRightReserved: 'All right reserved',
    termsOfUse: 'Terms of Use',
    privatePolicy: 'Privacy Policy',
    bannersGallery: 'Banners gallery',
    aboutUs: 'About us',
    contactUs: 'Contact us',
    termsOfUseURL: 'https://ai-banner.com/terms-use',
    privatePolicyURL: 'https://ai-banner.com/private-policy',
  },
  settings: {
    accountSettings: 'Account settings',
    newPassword: 'New password',
    oldPassword: 'Old password',
    repeatNewPassword: 'Repeat password',
    profile: 'Profile',
    name: 'Name',
    email: 'Email',
    emailLetters: 'Email letters',
    iWantToSendMe:
      'I want the company to send me emails with new products and promotions',
    subscription: 'Subscription',
    areYouSureToUnsub: 'Are you sure you want to cancel auto renewal?',
    cancelSubscription: 'Cancel auto renewal',
    paswordMismatch: 'Password mismatch',
    unableToUpdatePas: 'Unable to update password',
    paswordUpdatedSuccessfully: 'Password updated successfully',
    youHavntSub:
      'Subscription auto-renewal is disabled, at the end of the period your subscription will be canceled',
    subscriptionCanceledSuccess:
      'Auto renewal has been successfully cancelled.',
  },
  socialModal: {
    urlToSocial: 'Social network link',
    socialNetwork: 'Social network',
    socialNetworkCantBeEmpty: 'Social network cant be empty',
    socialCantBeEmpty: 'Social link cant be empty',
    other: 'Other',
  },
};
