<template>
  <div class="footer">
    <div class="footer--logo">
      <router-link :to="{ name: 'home' }" class="footer--logoIcon">
        <AppIcon icon="logo" :size="30" />
      </router-link>
      <div class="footer--logoDescription">
        {{ $t('newFooter.copyright') }}
        <br />
        {{ $t('newFooter.allRightReserved') }}
      </div>
    </div>
    <div class="footer--doc">
      <a
        :href="$t('newFooter.termsOfUseURL')"
        target="_blank"
        class="footer--docItem"
      >
        {{ $t('newFooter.termsOfUse') }}
      </a>
      <a
        :href="$t('newFooter.privatePolicyURL')"
        target="_blank"
        class="footer--docItem"
      >
        {{ $t('newFooter.privatePolicy') }}
      </a>
    </div>
    <div class="footer--nav">
      <router-link to="/banners" class="footer--navItem">
        {{ $t('newFooter.bannersGallery') }}
      </router-link>
      <a
        href="https://ai-banner.com/about-us"
        target="_blank"
        class="footer--navItem"
      >
        {{ $t('newFooter.aboutUs') }}
      </a>
      <a href="mailto:help@ai-banner.com" class="footer--navItem">
        <AppIcon :size="18" icon="email" />
        help@ai-banner.com
      </a>
    </div>
    <div class="footer--social">
      <a
        class="footer--socialItem"
        target="_blank"
        href="https://t.me/+x2aJilO6ZHQ3ZmQy"
      >
        <AppIcon :icon="'social-tg'" :size="18" />
      </a>
      <a
        class="footer--socialItem"
        target="_blank"
        href="http://instagram.com/ai.banner"
      >
        <AppIcon :icon="'social-inst'" :size="18" />
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding: 15px 30px;
  display: grid;
  grid-template-areas: 'logo doc nav social';
  row-gap: 20px;

  @media only screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'logo social' 'nav nav' 'doc doc';
  }

  @media only screen and (max-width: 580px) {
    row-gap: 40px;
  }

  &--navItem {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &--logo {
    grid-area: logo;
    column-gap: 16px;
    color: var(--color-dark);
    font-size: 10px;
    font-weight: 500;
    line-height: 17px;

    &Icon {
      color: var(--color-dark);
    }

    &Description {
      opacity: 40%;
    }
  }

  &--doc {
    grid-area: doc;
    column-gap: 24px;

    &Item {
      color: var(--color-dark);
      opacity: 40%;
      font-size: 10px;
      font-weight: 500;
      line-height: 17px;
      text-decoration: none;
    }
  }

  &--nav {
    grid-area: nav;
    column-gap: 30px;
    justify-self: end;

    @media only screen and (max-width: 400px) {
      column-gap: 10px;
    }

    &Item {
      color: var(--color-dark);
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      text-decoration: none;
    }
  }

  &--social {
    grid-area: social;
    column-gap: 20px;
    justify-self: end;

    &Item {
      color: var(--color-dark);
    }
  }

  &--logo,
  &--social,
  &--nav,
  &--doc {
    display: flex;
  }
}
</style>
