import { defineStore } from 'pinia';
import dayjs from '@/plugins/dayjs';

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      isModalAuthVisible: false,
      isModalCategoryVisible: false,
      isModalUserVisible: false,
      isModalColorSchemeVisible: false,
      isModalFeedbackVisible: false,
      isModalLimitsVisible: false,
      isModalHomeImageVisible: false,
      isModalSocialVisible: false,
      isLoaderVisible: false,
      isLoaderPartOpacity: false,
      countdownTime: 0,
      modalAuthProps: null,
    };
  },

  actions: {
    showModalAuth(modalType, extraProps) {
      this.isModalAuthVisible = modalType;
      this.modalAuthProps = extraProps || null;
    },
    showModalCategory(payload) {
      this.isModalCategoryVisible = payload;
    },
    showModalUser(payload) {
      this.isModalUserVisible = payload;
    },
    showModalColorScheme(payload) {
      this.isModalColorSchemeVisible = payload;
    },
    showModalFeedback(payload) {
      this.isModalFeedbackVisible = payload;
    },
    showModalLimitsVisible(payload) {
      this.isModalLimitsVisible = payload;
    },
    showModalHomeImageVisible(payload) {
      this.isModalHomeImageVisible = payload;
    },
    showModalSocialVisible(payload) {
      this.isModalSocialVisible = payload;
    },
    toggleAppLoader(payload, partVisible = false) {
      this.isLoaderVisible = payload;
      this.isLoaderPartOpacity = partVisible;
    },
    setCountdownTime(time) {
      this.countdownTime = time;
    },
  },

  getters: {
    countdown() {
      const duration = dayjs.duration(this.countdownTime, 'seconds');

      return {
        time: this.countdownTime,
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };
    },
    isAnyModalVisible() {
      return (
        this.isModalFeedbackVisible ||
        this.isModalAuthVisible ||
        this.isModalCategoryVisible ||
        this.isModalLimitsVisible ||
        this.isModalHomeImageVisible ||
        this.isModalUserVisible
      );
    },
  },
});
