<template>
  <div
    class="spacer"
    :style="{
      '--height': `${height}px`,
      '--width': `${width}px`,
    }"
  ></div>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: 0,
    },
    vertical: {
      type: Boolean,
    },
  },
  computed: {
    height() {
      return this.vertical ? this.size : 1;
    },
    width() {
      return this.vertical ? 1 : this.size;
    },
  },
};
</script>

<style lang="scss" scoped>
.spacer {
  min-width: var(--width);
  width: var(--width);
  min-height: var(--height);
  height: var(--height);
}
</style>
