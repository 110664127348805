<template>
  <div
    class="select_group"
    :class="{
      'select--fullHeight': fullHeight,
    }"
  >
    <vSelect
      v-bind="$attrs"
      :options="list"
      :modelValue="modelValue"
      @update:modelValue="update"
      class="select"
      :class="{
        'is-invalid': invalid,
        secondary: !isPrimary,
      }"
    >
      <template #open-indicator="{ attributes }">
        <AppIcon icon="chevron-down" :size="12" v-bind="attributes" />
      </template>
    </vSelect>
    <p class="select_error" v-if="invalid && error">
      <span>{{ error }}</span>
    </p>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  props: {
    view: {
      type: String,
      default: 'primary',
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
    modelValue: {
      type: null,
    },
    invalid: {
      type: null,
    },
    error: {
      type: String,
    },
  },
  computed: {
    isPrimary() {
      return this.view === 'primary';
    },
  },
  methods: {
    update($event) {
      this.$emit('update:modelValue', $event);
    },
  },
  components: {
    vSelect,
  },
};
</script>

<style lang="scss" scoped>
.select {
  &.is-invalid {
    --border-color: var(--color-error);
    animation: headShake 1s ease;

    :deep(.vs__dropdown-toggle) {
      border-color: var(--color-error);
    }
  }
  &--fullHeight {
    .select {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  :deep(.vs__dropdown-menu) {
    max-height: 320px;
    overflow-y: auto;
  }

  &.secondary {
    border-radius: 15px 0 0 15px;

    @media screen and (max-width: 833px) {
      border-radius: 15px 15px 0 0;
    }

    background: #fff;
    backdrop-filter: blur(21.45px);
    color: #25262c;
    z-index: 1;

    :deep(.vs__dropdown-toggle) {
      border: none;
    }
    :deep(.vs__search) {
      color: #25262c;
      &::placeholder {
        color: #25262c;
      }
    }

    &.vs--open {
      padding: 10px;
      border-radius: 15px 0 0 0;

      @media screen and (max-width: 833px) {
        border-radius: 15px 15px 0 0;
      }
    }

    :deep(.vs__dropdown-option--highlight) {
      background: #f2f2f2;
      color: #25262c;
    }

    :deep(.vs__dropdown-menu) {
      left: 0;
      right: unset;
      width: 100%;
      border: none;
      box-shadow: none;
      background: #fff;
      color: #25262c;
      transition: unset;
    }
  }
}
.select_error {
  color: var(--color-error);
  font-weight: 600;
  padding: 0.25em 1em;

  span {
    font-size: 12px;
  }
}
</style>
