<template>
  <AppModal v-model="isVisible" :close="closeModal">
    <div class="modal">
      <div class="modal--limits">
        <div class="modal--limits-info">
          {{ $t('limitsModal.downloadsLeft') }}
        </div>
        <div class="modal--limits-count">
          <span>{{ count }}</span> {{ $t('limitsModal.of') }}
          {{ downloadsLimit }}
        </div>
      </div>

      <div class="modal--content" v-if="extraLimitGone">
        <div class="modal--content-title">
          {{ $t('limitsModal.runOutOfFreeDownloads') }}
        </div>
        <AppButton
          class="modal--getPremium"
          color="golden"
          @click="onPremiumClick"
        >
          <img
            src="../../../assets/images/emoji-crown.png"
            width="20"
            class="icon"
          />
          {{ $t('app.getPremium') }}
        </AppButton>
      </div>

      <div class="modal--content" v-if="!extraLimitGone">
        <div class="modal--content-title">
          {{ $t('limitsModal.limitRunsOut') }}
          <span>
            {{ $t('limitsModal.discount70') }}
          </span>
          {{ $t('limitsModal.premiumAccess') }}
        </div>
        <div class="modal--content-description">
          {{ $t('limitsModal.takeAShortSurvey') }}
        </div>
      </div>

      <div class="modal--bottom" v-if="!extraLimitGone">
        <AppButton color="golden" @click="onPremiumClick">
          <img
            src="../../../assets/images/emoji-crown.png"
            width="20"
            class="icon"
          />
          {{ $t('app.getPremium') }}
        </AppButton>
        <AppButton color="primary" @click="onClick">
          {{ $t('limitsModal.getDiscount') }}
        </AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import {
  EXTRA_DOWNLOADS_LIMIT,
  PART_PREMIUM_LIMIT,
  DOWNLOADS_LIMIT,
} from '@/commonConstants/common';
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';

export default {
  setup() {
    return {
      appStore: useAppStore(),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      extraLimitGone: localStorage.getItem('isNewLimit'),
    };
  },
  computed: {
    isVisible() {
      return this.appStore.isModalLimitsVisible;
    },
    downloadsLimit() {
      if (this.userStore.hasPartPremium) {
        return PART_PREMIUM_LIMIT;
      }

      return this.extraLimitGone ? EXTRA_DOWNLOADS_LIMIT : DOWNLOADS_LIMIT;
    },
    count() {
      return this.userStore.user.limit;
    },
  },
  methods: {
    closeModal() {
      this.appStore.showModalLimitsVisible(false);
    },
    async onClick() {
      if (this.extraLimitGone) {
        return;
      }

      window.open('https://forms.gle/LtpsNwhSnPJtYiWm9', '_blank');

      await this.userStore.addExtraLimit();

      this.extraLimitGone = true;

      this.closeModal();
    },
    onTGClick() {
      window.open('https://t.me/malaniysater', '_blank');
    },
    onPremiumClick() {
      this.$router.push({ name: 'pricing' });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 50px 15px 30px 15px;

  &--limits {
    margin-bottom: 38px;
    color: #25262c4d;
    font-weight: 600;
    text-align: center;

    &-info {
      margin-bottom: 6px;
      font-size: 22px;
    }

    &-count {
      font-size: 42px;

      span {
        color: #25262c;
      }
    }
  }

  &--getPremium {
    width: 100%;
  }

  &--content {
    margin-bottom: 27px;
    text-align: center;

    &-title {
      margin-bottom: 11px;

      color: #25262c;
      font-size: 22px;
      font-weight: 600;
      line-height: 130%;

      span {
        color: #2467ec;
      }
    }

    &-description {
      color: #25262c;
      font-size: 14px;
      font-weight: 500;
      line-height: 153.6%;
    }
  }

  &--bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
}
</style>
