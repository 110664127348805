<template>
  <div class="input_group">
    <label
      v-if="$attrs.type === 'file'"
      class="input fileinput"
      :class="{
        'is-invalid': invalid,
        disabled: disabled,
        borderless,
      }"
    >
      <div
        class="fileinput_progress"
        :class="{ 'is-visible': uploadProgress }"
        :style="{ '--progress': `${uploadProgress}%` }"
      >
        <span>{{ $t('appInput.loading') }}</span>
      </div>
      <input
        type="file"
        ref="input"
        :accept="withSVG ? 'image/svg+xml' : 'image/png, image/jpeg, image/jpg'"
        @change="changeFileHandler"
        @focus="onFocus"
        :disabled="disabled || uploadProgress"
      />
      <span
        class="fileinput_label"
        v-show="modelValue?.name || modelValue?.url"
      >
        &lrm;{{ modelValue?.name || modelValue?.url }}
      </span>
      <span class="fileinput_placeholder">
        {{
          modelValue?.url || modelValue?.name
            ? $t('appInput.change')
            : $attrs.placeholder
        }}
      </span>
    </label>

    <label
      v-if="$attrs.type !== 'file'"
      class="input"
      :class="{
        'is-invalid': invalid,
        secondary: !isPrimary,
        'input--fullHeight': fullHeight,
        isDisabled: disabled,
        borderless,
        'with-right-slot': isHaveRightSlot,
      }"
    >
      <AppIcon v-if="iconLeft" :icon="iconLeft" :size="'2em'" />
      <input
        :id="id"
        v-bind="$attrs"
        :value="modelValue"
        @input.stop="updateValue"
        @blur="onBlur"
        :maxLength="maxLength"
        :disabled="disabled"
      />
      <slot name="right" />
    </label>

    <p class="input_error" v-if="invalid && error">
      <span>{{ error }}</span>
    </p>
  </div>
</template>

<script>
import axios from 'axios';
import Client from '@/plugins/axios';
import { useToaster } from '@/utils/useToaster';

export default {
  data: () => ({
    toaster: useToaster(),
    textChanged: false,
    uploadProgress: 0,
    cancelTokenSource: axios.CancelToken.source(),
  }),
  props: {
    borderless: {
      type: Boolean,
    },
    withSVG: {
      type: Boolean,
    },
    iconLeft: {
      type: Object,
    },
    view: {
      type: String,
      default: 'primary',
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    modelValue: {
      type: null,
    },
    invalid: {
      type: null,
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: String,
    },
    autoupload: {
      type: Boolean,
    },
    maxLength: { type: String },
  },
  computed: {
    isPrimary() {
      return this.view === 'primary';
    },
    isHaveRightSlot() {
      return this.$slots.right;
    },
  },
  methods: {
    onFocus($event) {
      this.$emit('focus', $event);
    },
    updateValue($event) {
      this.textChanged = true;
      this.$emit('update:modelValue', $event.target.value);
    },
    onBlur() {
      if (this.textChanged) {
        this.textChanged = false;
        this.$emit('blurAfterChange');
      }
    },

    // File methods
    changeFileHandler($event) {
      if (this.autoupload) {
        this.uploadFile($event);
      } else {
        this.returnFile($event);
      }
    },
    async uploadFile() {
      const [file] = this.$refs.input.files;

      if (!file) {
        return;
      }
      const config = {
        cancelToken: this.cancelTokenSource.token,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.uploadProgress = progress;
        },
      };
      const formData = new FormData();
      formData.append('file', file, file.name);

      try {
        const { data } = await Client.post(
          'upload_file',
          formData.fd ?? formData,
          config,
        );
        this.$emit('uploaded', data?.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          this.toaster.error(this.$t('appInput.youCanceledImageUpload'));
        } else {
          this.toaster.error(this.$t('appInput.unableToUploadImage'));
        }
      } finally {
        const input = this.$refs.input;
        if (input) {
          input.value = null;
        }
        this.uploadProgress = 0;
      }
    },
    returnFile() {
      const [file] = this.$refs.input.files;
      let fileData;

      if (file) {
        fileData = {
          file,
          name: file.name,
        };
      }
      this.$emit('update:modelValue', fileData);
    },
  },
  mounted() {
    if (this.$attrs.type === 'file') {
      this.$emit('inputRef', this.$refs.input);
    }
  },
  beforeUnmount() {
    this.cancelTokenSource.cancel();
  },
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  column-gap: 14px;
  align-items: center;
  --border-color: #cfcfcf;
  background: none;
  box-shadow: 0 0 0 2px var(--border-color) inset;
  box-sizing: border-box;
  border-radius: 15px;
  height: 45px;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  color: inherit;
  padding: 0 1em;
  transition: box-shadow 0.125s ease;

  &.borderless {
    box-shadow: none;
  }

  input {
    width: 100%;
    display: block;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    color: inherit;
    background: none;
    outline: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &:focus {
    --border-color: var(--color-accent);
  }

  &.is-invalid {
    --border-color: var(--color-error);
    animation: headShake 1s ease;
  }

  &.secondary {
    box-shadow: unset;
    background: #ffffff;
    backdrop-filter: blur(21.45px);
    color: #94a4ad;

    &::placeholder {
      color: #94a4ad;
    }
  }

  &.isDisabled {
    color: #e7e7e7;
  }

  &--fullHeight {
    height: 100%;
  }
}

.input_error {
  color: var(--color-error);
  font-weight: 600;
  padding: 0.25em 1em;

  span {
    font-size: 12px;
  }
}

.fileinput {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &_progress {
    content: '';
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    left: 0;
    border-radius: inherit;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: 0.125s ease;

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background: var(--color-accent);
      opacity: 0.35;
    }

    &::after {
      position: absolute;
      width: var(--progress);
      height: 100%;
      left: 0;
      content: '';
      background: var(--color-accent);
      transition: 0.075s ease;
    }

    span {
      position: relative;
      color: #fff;
      z-index: 1;
    }

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    z-index: -1;
  }

  &_placeholder {
    color: #989898;
    color: var(--color-accent);
    text-decoration: underline;
    text-underline-offset: 0.125em;
    text-decoration-thickness: 0.125em;
  }

  &_label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: rtl;
    line-height: 1.5em;
    margin-right: 0.5em;
    text-align: left;
  }

  &.disabled {
    cursor: default;

    .fileinput_placeholder {
      color: #e7e7e7;
    }
  }
}

.with-right-slot {
  padding-right: 6px;
}
</style>
