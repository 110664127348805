<template>
  <div class="recaptchaCompliance">
    {{ $t('recaptchaCompliance.siteIsProtectedBy') }}
    <a href="">{{ $t('recaptchaCompliance.privacyPolicy') }}</a>
    {{ $t('recaptchaCompliance.and') }} <a href=""></a>
    {{ $t('recaptchaCompliance.google') }}
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.recaptchaCompliance {
  font-weight: 500;
  font-size: 10px;
  line-height: 160%;
  text-align: center;
  color: #989898;

  a {
    color: var(--color-accent);
    text-decoration: none;
  }
}
</style>
