<template>
  <div class="availableLimit--wrapper">
    <div class="availableLimit--available">
      {{ $t('availableLimit.available') }}
    </div>
    <div class="availableLimit--limits">
      <span>{{ count }}</span>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';

export default {
  setup: () => {
    return {
      appStore: useAppStore(),
      userStore: useUserStore(),
    };
  },
  computed: {
    count() {
      return this.userStore.user.limit;
    },
  },
};
</script>

<style lang="scss" scoped>
.availableLimit {
  &--wrapper {
    border-radius: 15px;
    background: #fff;
    display: flex;
    padding: 14px 9px;
    font-weight: 600;
    column-gap: 19px;

    @media screen and (max-width: 780px) {
      row-gap: 10px;
      flex-direction: column;
      align-items: center;
    }
  }

  &--available {
    color: #25262c;

    @media screen and (max-width: 1020px) {
      display: none;
    }
  }

  &--limits {
    width: max-content;
    color: #bdbfc9;

    span {
      color: #2467ec;
    }
  }
}
</style>
