<template>
  <div class="toast" :class="[`toast-${type}`]">
    <div class="toast_icon">
      <img :src="require(`@/assets/images/${icon}.png`)" alt="" />
    </div>
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return 'emoji-smiling-cat-with-heart-eyes';

        case 'info':
          return 'emoji-grinning-cat';

        case 'error':
          return 'emoji-sad-cat';

        case 'warning':
          return 'emoji-weary-cat';

        default:
          return 'emoji-grinning-cat';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  display: flex;
  align-items: center;
  position: relative;
}
.toast_icon {
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--toast-color);
    opacity: 0.25;
    border-radius: inherit;
    left: 0;
    top: 0;
  }
  img {
    width: 35px;
    height: 35px;
    position: relative;
  }
}
</style>
